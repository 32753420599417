<template>
  <div class="bar-chart">
    <!-- Header -->
    <div
      slot="header"
      class="bar-chart-header card-title"
      :style="type === 'alt' ? { minHeight: '7rem' } : null"
    >
      <!-- Sum -->
      <div
        v-if="!hideTotal"
        class="list-sum"
      >
        TOTAL:

        {{ valuesSum | formatLocale }}
      </div>

      <!-- Title -->
      <div class="bar-chart-header--title">
        <span
          v-if="type === 'alt'"
          class="m-0"
        >
          {{ title }}
        </span>
        <span
          v-else
          class="m-0"
        >
          {{ title.toUpperCase() }}
        </span>

        <div class="bar-chart-header--actions">
          <template v-if="listData.length > 0">
            <vs-tooltip
              delay=".4s"
              :text="'Ordenar Por: ' + (sortBy === 'value' ? 'Valor' : 'Nome')"
            >
              <button
                class="sort-list-btn"
                @click="toggleSort"
              >
                <i class="mdi mdi-sort" />
              </button>
            </vs-tooltip>
          </template>
          <vs-tooltip
            delay=".4s"
            :text="checkItemAccess('LGPD') ? 'Exportar: ' + title : 'Necessário permissão para exportar!'"
            :style="checkItemAccess('LGPD') ? '' : 'opacity:0.35;'"
          >
            <button
              class="sort-list-btn"
              style="margin-left:10px;"
              :disabled="checkItemAccess('LGPD') ? false : true"
              @click="exportIndicatorValue"
            >
              <i class="mdi mdi-download" />
            </button>
          </vs-tooltip>
        </div>
      </div>
    </div>

    <!-- Content -->
    <div class="bar-chart-content">
      <!-- Bar List -->
      <ul
        v-if="listData.length > 0"
        class="list-inline mt-3 mb-2 mr-2"
      >
        <li
          v-for="(item, index) in listData"
          :key="index"
          class="mb-4"
        >
          <h2
            class="mb-0"
            style="font-size:1.6rem;"
          >
            {{ item.value | formatLocale }}
          </h2>

          <div class="d-flex align-items-center justify-content-between">
            <small style="font-size:0.73rem;">
              {{ item.text.replace("otherText=", "") }}
            </small>

            <div
              style="font-size:0.95rem;"
              class="ml-2 mb-1 text-value"
            >
              {{ item.perc | formatNumber }}%
            </div>
          </div>

          <vs-progress
            :percent="item.perc"
            :color="colorSecondary"
          />
        </li>
      </ul>

      <div
        v-else
        class="d-flex justify-content-center mt-2"
      >
        <span>Nenhum dado encontrado.</span>
      </div>
    </div>
  </div>
</template>

<script>
/* Services */
import Survey from '@/services/survey';

const surveyService = new Survey();

export default {
  name: 'BarChart',
  props: {
    data: {
      type: Array,
      default: () => ([]),
    },
    indicator: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    listData: [],
    selected: [],
    sortBy: 'value',
    showLeft: false,
    showRight: false,
    maxList: 4,
    currentx: 0
  }),
  computed: {
    colorSecondary() {
      return this.$store.state.configBox.cor2;
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
    hideTotal() {
      const visibility = this.$store.state.visibilityOptions;

      return (
        Object.keys(visibility).length > 0
        && visibility.hideChartTotal
      );
    },
    valuesSum() {
      return this.listData.reduce((a, b) => a + b.value, 0);
    },
  },
  created() {
    this.listData = [...this.data];

    if (this.data.length <= this.maxList) {
      this.selected = this.data;

      this.showLeft = false;
      this.showRight = false;
    } else {
      this.showLeft = false;
      this.showRight = true;

      this.selectList();
    }
  },
  methods: {
    exportIndicatorValue() {
      surveyService.exportIndicatorValue(
        this.indicator,
        this.$store.state.configBox.nouns.prj,
        this.title.replaceAll(' ', '_'),
        this.token,
      )
        .then((res) => (this.downloadCSV(res, this.title)))
        .catch((err) => alert(err));
    },
    checkItemAccess(access) {
      if (!access) return true;

      if (this.$store.state.loginData.accessValues.includes(access)) {
        return true;
      }

      return false;
    },
    downloadCSV(content, fileName) {
      const csvData = new Blob([content], { type: 'text/csv' });
      const csvURL = URL.createObjectURL(csvData);
      const link = document.createElement('a');
      link.href = csvURL;
      link.setAttribute('download', fileName);
      link.click();
    },
    selectList() {
      if (this.maxList + this.currentx <= this.data.length) {
        this.selected = [];

        for (var i = this.currentx; i < (this.maxList + this.currentx); i++) {
          this.selected.push(this.data[i]);
        }
      }
    },
    toggleSort() {
      if (this.sortBy === 'value') {
        this.sortBy = 'name';

        // Sort by Indicator Name
        const updated = [...this.data];

        this.listData = updated.sort((a, b) => a.text.localeCompare(b.text));
      } else if (this.sortBy === 'name') {
        // Sort by Value: default
        this.sortBy = 'value';
        this.listData = [...this.data];
      }
    }
  }
};
</script>

<style scoped lang="scss">
.bar-chart {
  background-color: white;
  border: 0;
  border-radius: .3rem;
  display: flex;
  flex-flow: column nowrap;
  height: 500px;
  margin-bottom: 1rem;

  .bar-chart-header--actions {
    bottom: 20px;
    display: flex;
    position: relative;
    top: 0;
  }

  /* Header */
  .bar-chart-header {
    background-color: rgba(0, 0, 0, .64);
    border: 0;
    border-radius: .3rem .3rem 0 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    display: flex;
    flex-flow: column nowrap;
    gap: 0.1rem;
    padding: 0.8rem 1rem;

    .bar-chart-header--title {
      align-items: baseline;
      color: #FAFAFA;
      display: flex;
      font-size: 1em !important;
      font-weight: 500;
      gap: 0.5rem;
      justify-content: space-between;
      letter-spacing: 0.25px;
      -webkit-font-smoothing: antialiased;

      .sort-list-btn {
        align-items: center;
        background: none;
        background-color: rgba(255, 255, 255, 0.120);
        border: none;
        border-radius: 3px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        outline: none;
        height: 1.5rem;
        width: 1.5rem;

        i {
          color: white;
          font-size: 1.06em;
          max-height: 22.74px;
        }
      }
    }

    .list-sum {
      align-items: baseline;
      justify-content: space-between;
      display: flex;
      flex-flow: row nowrap;
      color: #d6d3d1;
      font-size: 0.75rem !important;
      letter-spacing: 0.4px;
      white-space: nowrap;
    }
  }

  /* Content */
  .bar-chart-content {
    overflow: auto;
    padding: 0 1rem 1rem;

    .text-value {
      color: black;
      font-size: 0.85rem;
      font-weight: bold;
      height: 15px;
    }
  }
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b1b1b1;
}
</style>
