import axios from 'axios';

const baseDomain = process.env.VUE_APP_BASEDOMAIN;
const baseEnv = process.env.VUE_APP_BASEENV;

const baseUrl = `${baseDomain}/${baseEnv}`;

export default class Filter {
  deleteFilter(filterUuid) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/filters/DeleteFilter/${filterUuid}`;

      axios
        .delete(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getFilters(ownerId) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/filters/${ownerId}`;

      axios
        .get(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  prepareFilter(ownerId, filterUuid, filter) {
    return new Promise((resolve, reject) => {
      if (!filterUuid) filterUuid = '-';

      const url = `${baseUrl}/idregister/${ownerId}/${filterUuid}`;

      axios
        .post(url, filter)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  saveFilter(ownerId, filterName, access, filterId, filterUuid) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/filters`;

      const filter = {
        FilterId: filterId,
        ProjId: ownerId,
        Name: filterName,
        AccessLevel: access,
        Uuid: filterUuid,
      };

      axios
        .post(url, filter)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getFiltersOptions(ownerId, filterId) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/filters/${ownerId}/${filterId}`;

      axios
        .get(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getFilterVisions(ownerId) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/filtervisions/${ownerId}`;

      axios
        .get(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getIndicatorValues(filterUuid) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/filtervisions/indicatorvalues/${filterUuid}`;

      axios
        .get(url)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getVisionIndicators(filterUuid) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/filtervisions/visionindicators/${filterUuid}`;

      axios.get(url).then(
        (resp) => {
          if (resp.data.header.codigo == 1) {
            resolve(resp.data.data);
          } else {
            reject(resp.data.header.msg);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  updateIndicatorsList(filterUuid, indicators) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/filtervisions/updateindicatorslist/${filterUuid}`;

      axios
        .post(url, indicators)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  updateFilterVisibility(filterId, status) {
    return new Promise((resolve, reject) => {
      const url = `${baseUrl}/filters/UpdateVisionStatus`;
      const obj = { filterId: filterId, isActive: status };

      axios
        .post(url, obj)
        .then(
          (resp) => {
            if (resp.data.header.codigo == 1) {
              resolve(resp.data.data);
            } else {
              reject(resp.data.header.msg);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
