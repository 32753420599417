<template>
  <div class="donut-chart">
    <!-- Header -->
    <header
      slot="header"
      class="donut-chart-header card-title"
    >
      <!-- Title -->
      <div class="donut-chart-header--title">
        <span class="m-0">
          {{ title.toUpperCase() }}
        </span>

        <div class="donut-chart-header--actions">
          <template v-if="listData.length > 0">
            <vs-tooltip
              delay=".4s"
              :text="'Ordenar Por: ' + (sortBy === 'value' ? 'Valor' : 'Nome')"
            >
              <button
                class="sort-list-btn"
                @click="toggleSort"
              >
                <i class="mdi mdi-sort" />
              </button>
            </vs-tooltip>
          </template>
        </div>
      </div>
    </header>

    <!-- Content -->
    <div class="donut-chart-content">
      <vue-apex-charts
        type="donut"
        height="250"
        :options="chartOptions"
        :series="series"
      />

      <div class="donut-percentage-list">
        <ul>
          <li
            v-for="(item, itemIdx) in listData"
            :key="item.text"
          >
            <div>
              <i
                class="mdi mdi-checkbox-blank-circle item-bullet"
                :style="{ color: getLegendColor(itemIdx) }"
              />

              <span>{{ item.text }}</span>
            </div>

            <span>{{ item.perc | formatNumber }}%</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/* Components */
import VueApexCharts from 'vue-apexcharts';

/* Helpers */
import numeral from 'numeral';
import jsgradient from './jsGradient.js';
const gradient = new jsgradient;

export default {
  name: 'DonutChart',
  filters: {
    formatNumber(value) {
      return numeral(value).format('0.0');
    },
  },
  components: { VueApexCharts },
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    listData: [],
    sortBy: 'value',
  }),
  computed: {
    /* Theme */
    colorSecondary() {
      return this.$store.state.configBox.cor2;
    },
    colorSecondaryLighter() {
      let hexColor = this.colorSecondary;

      // Remove the '#' symbol and convert to RGB
      hexColor = hexColor.slice(1);
      const r = parseInt(hexColor.slice(0, 2), 16);
      const g = parseInt(hexColor.slice(2, 4), 16);
      const b = parseInt(hexColor.slice(4, 6), 16);

      // Calculate the new RGB values based on the percentage
      const newR = Math.max(0, Math.min(255, r + 1.5 * r));
      const newG = Math.max(0, Math.min(255, g + 1.5 * g));
      const newB = Math.max(0, Math.min(255, b + 1.5 * b));

      // Convert the new RGB values back to HEX
      const lighterHexColor =
        `#${Math.round(newR).toString(16).padStart(2, '0')}${Math.round(newG).toString(16).padStart(2, '0')}${Math.round(newB).toString(16).padStart(2, '0')}`;

      return lighterHexColor;
    },
    /* Chart */
    chartOptions() {
      return {
        labels: this.data.map((item) => item.text),
        dataLabels: {
          enabled: false,
        },
        grid: {
          padding: {
            left: 0,
            right: 0,
          },
          borderColor: 'rgba(0, 0, 0, 0.1)',
        },
        plotOptions: {
          pie: {
            donut: {
              size: '70px',
            },
          },
        },
        stroke: {
          width: 0,
        },
        legend: {
          show: false,
        },
        colors: this.getColorGradient(),
        tooltip: {
          fillSeriesColor: false,
        },
      };
    },
    series() {
      return this.data.map((item) => item.value);
    },
  },
  created() {
    this.listData = [...this.data];
  },
  methods: {
    /* Helpers */
    getColorGradient() {
      return gradient.generateGradient(
        this.colorSecondaryLighter,
        this.colorSecondary,
        this.data.length,
      );
    },
    getLegendColor(index) {
      return this.chartOptions.colors[index];
    },
    toggleSort() {
      if (this.sortBy === 'value') {
        this.sortBy = 'name';

        // Sort by Indicator Name
        this.listData = [...this.data];
      } else if (this.sortBy === 'name') {
        // Sort by Value: default
        this.sortBy = 'value';

        let updated = [...this.data];
        updated = updated.sort((a, b) => parseFloat(b.perc) - parseFloat(a.perc));
        this.listData = updated;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.donut-chart {
  background-color: white;
  border: 0;
  border-radius: .3rem;
  display: flex;
  flex-flow: column nowrap;
  height: 500px;
  overflow: hidden;

  /* Header */
  .donut-chart-header {
    background-color: rgba(0, 0, 0, .64);
    border: 0;
    border-radius: .3rem .3rem 0 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    display: flex;
    flex-flow: column nowrap;
    gap: 0.1rem;
    padding: 0.8rem 1rem;

    .donut-chart-header--title {
      align-items: baseline;
      color: #FAFAFA;
      display: flex;
      font-size: 1em !important;
      font-weight: 500;
      gap: 0.5rem;
      justify-content: space-between;
      letter-spacing: 0.25px;
      -webkit-font-smoothing: antialiased;

      .donut-chart-header--actions {
        bottom: 20px;
        display: flex;
        position: relative;
        top: 0;

        .sort-list-btn {
          align-items: center;
          background: none;
          background-color: rgba(255, 255, 255, 0.120);
          border: none;
          border-radius: 3px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          outline: none;
          height: 1.5rem;
          width: 1.5rem;

          i {
            color: white;
            font-size: 1.06em;
            max-height: 22.74px;
          }
        }
      }
    }
  }

  .donut-chart-content {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    padding: 0 1rem;
    min-height: 0;
    padding-bottom: 1rem;

    .donut-percentage-list {
      background-color: #FAFAFA;
      border: 0;
      border-radius: 0.3rem;
      overflow: auto;
      padding: 0.5rem 1rem;
      width: 100%;

      ul {
        list-style: none;
        margin: 0;

        li {
          align-items: center;
          display: flex;
          padding: 0.45rem 0;
          justify-content: space-between;
          overflow-y: auto;

          .item-bullet {
            font-size: 13px;
            margin-right: 0.5rem;
          }
        }
      }

      // Scrollbar
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 20px;
        border: transparent;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #b1b1b1;
      }
    }
  }
}
</style>
