<template>
  <div class="vision-details">
    <header class="d-flex align-items-center justify-content-between px-2 pb-2">
      <vs-button
        color="primary"
        icon="arrow_back"
        text-color="white"
        @click="goBack"
      >
        Voltar
      </vs-button>

      <span :style="{ fontSize: '0.915' }">
        <span class="mr-2 text-white">
          Filtro selecionado:
        </span>

        <span class="text-muted">
          {{ filterName }}
        </span>
      </span>
    </header>

    <!-- Tabs -->
    <vs-tabs v-model="selectedTab">
      <!-- Data Analysis Tab -->
      <vs-tab label="Análise de dados">
        <vs-col
          type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="12"
          vs-xs="12"
        >
          <vs-card>
            <h3>Análise de dados</h3>

            <div class="data-analysis--content">
              <!-- Categories / Indicator Groups -->
              <vs-collapse
                class="indicator-groups"
                accordion
              >
                <vs-collapse-item
                  v-for="(grp, grpIdx) in indicatorGroups"
                  :key="grpIdx"
                  icon-arrow="add"
                >
                  <div
                    slot="header"
                    class="font-weight-bold mb-2"
                  >
                    <i
                      :class="grp.icon"
                      class="mr-3"
                    />

                    {{ grp.group }}
                  </div>

                  <vs-card
                    v-for="(box, bIdx) in grp.boxes"
                    :key="bIdx"
                    class="cardx px-2 py-2 shadow"
                    icon-arrow="arrow_drop_up"
                  >
                    <div
                      slot="header"
                      class="mb-2 text-uppercase"
                    >
                      {{ box.title }}
                    </div>

                    <!-- Indicator Chips -->
                    <div
                      slot="media"
                      class="pl-1 pr-1"
                    >
                      <div
                        v-for="(indicator, indIdx) in box.indexes"
                        :key="indIdx"
                        class="col-md-12 col-sm-12"
                        @click="addIndicator(indicator)"
                      >
                        <vs-tooltip
                          v-if="!indicator.indId"
                          text="Indicador em manutenção"
                        >
                          <vs-chip
                            class="chips-margin"
                            color="#adaca6"
                          >
                            {{ indicator.idxText }}
                          </vs-chip>
                        </vs-tooltip>

                        <vs-chip
                          v-else
                          :key="chipRenderKey"
                          class="chips-margin"
                          :color="selectedIndicators.find((opt) => opt.indId === indicator.indId) ? colorSecondary : 'dark'"
                        >
                          {{ indicator.idxText }}
                        </vs-chip>
                      </div>
                    </div>
                  </vs-card>
                </vs-collapse-item>
              </vs-collapse>

              <!-- Selected Indicators -->
              <div class="selected-indicators">
                <h3 class="text-uppercase font-weight-bold">
                  Indicadores selecionados:
                </h3>

                <h4 v-if="!selectedIndicators || !selectedIndicators.length">
                  Nenhum indicador selecionado.
                </h4>

                <div
                  v-else
                  class="d-flex flex-wrap px-5 align-items-center"
                >
                  <vs-chip
                    v-for="(selectedInd, siIdx) in selectedIndicators"
                    :key="siIdx"
                    class="w-auto"
                    :color="colorPrimary"
                    closable
                    @click="removeIndicator(selectedInd)"
                  >
                    {{ selectedInd.idxText }}{{ selectedInd.description }}
                  </vs-chip>
                </div>

                <vs-button
                  size="large"
                  @click="updateVisionIndicators"
                >
                  Atualizar Indicadores
                </vs-button>
              </div>
            </div>
          </vs-card>
        </vs-col>
      </vs-tab>

      <!-- Results Tab -->
      <vs-tab
        label="Resultados"
        :disabled="visionLists.length < 1"
      >
        <vs-row vs-justify="flex-start">
          <vs-col
            v-for="(ls, lsIdx) in visionLists"
            :key="lsIdx"
            vs-lg="4"
          >
            <BarChart
              v-if="ls.type === 'Lista'"
              :key="ls.code"
              :data="ls.values"
              :title="ls.title"
            />

            <DonutChart
              v-if="ls.type == 'Donut'"
              :key="ls.code"
              :title="ls.title.toUpperCase()"
              :data="ls.values"
            />
          </vs-col>
        </vs-row>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
/* Components */
import BarChart from '../../dashboard-components/box-fanbase/BarChart.vue';
import DonutChart from '../../dashboard-components/box-fanbase/DonutChart.vue';

/* Services */
import Filter from '@/services/filter';
import Survey from '@/services/survey';

const filterService = new Filter();
const surveyService = new Survey();

export default {
  name: 'VisionDetails',
  components: {
    BarChart,
    DonutChart,
  },
  data: () => ({
    indicatorGroups: [],
    selectedIndicators: [],
    visionLists: [],
    chipRenderKey: 0,
    selectedTab: 0
  }),
  computed: {
    filterUid() {
      return this.$store.state.filterUid;
    },
    filterName() {
      return this.$store.state.filterName;
    },
    ownerId() {
      return this.$store.state.ownerID;
    },
    /* Theme */
    colorPrimary() {
      return this.$store.state.configBox.cor1;
    },
    colorSecondary() {
      return this.$store.state.configBox.cor2;
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
  },
  beforeMount() {
    // Get all indicator groups
    this.getIndicatorGroups();

    // Get indicators already added
    this.getVisionIndicators();

    // Get vision results
    this.getVisionLists();
  },
  beforeDestroy() {
    // Clear filter uid from state before it's use by other components
    this.$store.dispatch('setFilterUid', null);
  },
  methods: {
    /* API */
    getIndicatorGroups() {
      this.$store.dispatch('set_isLoadingActive', true);

      surveyService
        .getBoxesAsFilters(this.ownerId, this.token)
        .then(
          (resp) => {
            this.indicatorGroups = resp;

            this.$store.dispatch('set_isLoadingActive', false);
          },
          (err) => {
            this.$store.dispatch('set_isLoadingActive', false);

            alert(err);
          }
        );
    },
    getVisionIndicators() {
      this.$store.dispatch('set_isLoadingActive', true);

      filterService
        .getVisionIndicators(this.filterUid)
        .then(
          (resp) => {
            this.selectedIndicators = resp;
            this.$store.dispatch('set_isLoadingActive', false);
          },
          (err) => {
            this.$store.dispatch('set_isLoadingActive', false);
            alert(err);
          }
        );
    },
    getVisionLists() {
      this.$store.dispatch('set_isLoadingActive', true);

      filterService
        .getIndicatorValues(this.filterUid)
        .then(
          (resp) => {
            this.visionLists = resp.listas;

            this.$store.dispatch('set_isLoadingActive', false);

            if (resp.listas.length > 1) {
              this.selectedTab = 2;
            }
          },
          (err) => {
            this.$store.dispatch('set_isLoadingActive', false);
            alert(err);
          }
        );
    },
    updateVisionIndicators() {
      this.$store.dispatch('set_isLoadingActive', true);

      // Create list of indicator id's
      const selectedIds = this.selectedIndicators.map((si) => si.indId);

      filterService
        .updateIndicatorsList(this.filterUid, selectedIds)
        .then(
          () => {
            this.$store.dispatch('set_isLoadingActive', false);

            this.getVisionLists();
          },
          (err) => {
            this.$store.dispatch('set_isLoadingActive', false);
            alert(err);
          }
        );
    },
    /* Helpers */
    addIndicator(indicator) {
      const found = this.selectedIndicators.find((si) => si.indId === indicator.indId);
      if (!found) this.selectedIndicators.push(indicator);
    },
    removeIndicator(indicatorId) {
      const updated = this.selectedIndicators.filter((si) => si.indId != indicatorId.indId);
      this.selectedIndicators = updated;
      this.chipRenderKey++;
    },
    /* Router */
    goBack() {
      this.$store.state.filterName = '';
      this.$store.dispatch('setFilterUid', null);
      this.$router.push('/visions');
    },
  }
}
</script>

<style lang="scss">
.vision-details {
  border-radius: 0.25rem;

  .ul-tabs.vs-tabs--ul.ul-tabs-left {
    background-color: #FFF;
    border-radius: 0.25rem;
  }

  .con-vs-tabs .con-slot-tabs {
    border-radius: 0 0 0.25rem 0.25rem;
  }
}

.data-analysis--content {
  align-items: stretch;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  min-height: 300px;
}

.selected-indicators {
  width: 500px;
  max-height: 400px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
}

.vs-tabs--li {
  display: block;
  position: relative;
}

.con-pagination-table.vs-table--pagination {
  justify-content: center;
  padding: 15px;
}
</style>
